import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";

function App() {
  //creating IP state

  const [ipData, setIpData] = useState({});

  const getData = async () => {
    const res = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=8eee905f33004f79a4604772fa6dd117");
    console.log(res.data);
    setIpData({
      ip: res.data.ip,
      country_name: res.data.country_name,
      country_capital: res.data.country_capital,
      state_prov: res.data.state_prov,
      isp: res.data.isp,
      organization: res.data.organization,
      time: res.data.time,
    });
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  if (!ipData) return <h1>Loading...</h1>;

  return (
    <div className="App">
      <h1>Here are your Public Network Details</h1>
      <h2> IP Address : {ipData.ip}</h2>
      <h2> Country : {ipData.country_name}</h2>
      <h2> Country Capital : {ipData.country_capital}</h2>
      <h2> State : {ipData.state_prov}</h2>
      <h2> ISP Name : {ipData.isp}</h2>
      <h2> Organization : {ipData.organization}</h2>
      <h2> Time : {ipData.current_time}</h2>

    </div>
  );
}

export default App;
